import React, { useEffect, useState } from "react";
import "../css/VideoPlayPage.css";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { db } from "../config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const MiniVideoCard = (props) => {
  const navigate = useNavigate();
  const [creatorData, setCreatorData] = useState(null);
  const usersCollection = collection(db, "users");
  const [schoolData, setSchoolData] = useState(null);
  const schoolsCollection = collection(db, "schools");

  console.log(props.isPremium)


  useEffect(() => {
    const q = query(schoolsCollection, where("admin", "==", props.creator_id));
    getDocs(q).then((docs) => {
      setSchoolData(docs.docs.at(0).data());
    });
  }, []);

  const getTime = (created_time) => {
    const date = new Date(created_time.seconds * 1000);
    return date.toDateString();
  };

  return (
    <div className="min-video-card" onClick={() => {
      window.location= `/play/${props.id}`
    }}>
      <div className="tiny-video-thumbnail-container" >
      <img
        src={props.img_url}
        className="tiny-video-thumbnail"
        
      />
      {props.isPremium && <div className="thumbnail-icon-container">
      <span style={{color:"white", backgroundColor: "forestgreen", borderRadius: "10px", paddingLeft: "5px", paddingRight: "5px", paddingBottom: "2px"}}>premium</span>
      </div>}
      </div>
      <div className="tiny-description">
        <div className="tiny-title">
          <span style={{ fontWeight: 450 }}>{props.title}</span>
        </div>
        <Grid container spacing={1} style={{ fontWeight: 350 }}>
          <Grid item xs={2}>
            <Avatar
              sx={{ maxWidth: 25, maxHeight: 25 }}
              src={schoolData?.logo_url}
            ></Avatar>
          </Grid>
          <Grid item xs={8}>
            <span className="tiny-channel-name">
            {schoolData?.name}
            </span>
            <br />
            <span className="create-date">{props && getTime(props.date)}</span>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MiniVideoCard;
