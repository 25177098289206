import "../../css/VideoPlayPage.css";
import React from "react";
import Header from "../../components/Header";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CardHeader from "@mui/material/CardHeader";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Comments from "../../components/Comments";
import { auth, db } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Report from "../../components/Report";
import Like from "../../components/Like";
import ReactPlayer from "react-player/lazy";
import {
  addDoc,
  query,
  getDoc,
  getDocs,
  where,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import CopyToClipboard from "react-copy-to-clipboard";
import ViewsCount from "../../components/ViewsCount";
import LikesCount from "../../components/LikesCount";
import DiscussionReferences from "../../components/DiscussionReferences";
import SimilarContent from "../../components/SimilarContent";
import Page from "../../components/Page";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import AdHeader from "../../components/AdHeader";
import Follow from "../../components/Follow";
import { IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LoadingPage from "../../components/LoadingPage";
import { useNavigate } from "react-router-dom";


const VideoPlayPage = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [channel, setChannel] = useState(null);
  const [adDone, setAdDone] = useState(true);
  const [ad, setAd] = useState(null);
  const [user] = useAuthState(auth);
  const [viewed, setViewed] = useState(false);
  const [interacted, setInteracted] = useState(false);
  const [discussionVisible, setDiscussionVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const [idState, setIDState] = useState(id);
  const [subscribed, setSubscribed] = useState(null);
  const usersCollection = collection(db, "users");
  const viewsColl = collection(db, "views");
  const adColl = collection(db, "adverts");
  const [schoolData, setSchoolData] = useState(null);
  const schoolsCollection = collection(db, "schools");
  const subscriptionsCollection = collection(db, "subscriptions");
  const navigate = useNavigate();


  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(()=> {
    const getSubscriptions = async () => {
      if (user && data?.isPremium && !subscribed) {
        const q1 = query(subscriptionsCollection, where("uid", "==", user.uid));
        const snapShots = await  getDocs(q1);
        const institutions = []
        snapShots.docs.map((snapShot) => institutions.push(snapShot.data().institution))
        const q2 = query(schoolsCollection, where("videos", "array-contains", id));
        const docs = await getDocs(q2)
        const school = docs.docs.at(0).data()
        const docId = docs.docs.at(0).id;
        if(institutions.find(item => item===docId) || school.admin === user.uid){
        setSubscribed(true);
        }
        else{
          navigate(`/school/subscribe/${docId}`);
        }
      }
    }
    getSubscriptions();
    
    }, [user, data])


  const [favorite, setFavorite] = useState(false);

  const updateWatch = () => {
    setViewed(true);
    const viewData = {
      uid: user.uid,
      video_id: id,
      date: serverTimestamp(),
      creator: data.user_id,
    };
    addDoc(viewsColl, viewData)
      .then((docRef) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const updateInteraction = async () => {
    const videoRef = doc(db, "videos", id);
    const videoDoc = await (await getDoc(videoRef)).data();
    const updateFields = {
      views: videoDoc.views + 1,
    };

    await updateDoc(videoRef, updateFields);
    setInteracted(true);
  };

  const getVideoChannel = async () => {
    const videoRef = doc(db, "school_videos", id);
    const videoDoc = await (await getDoc(videoRef)).data();

    setData({ ...videoDoc, id: id });
    if (data) {
      const q = query(usersCollection, where("uid", "==", data.user_id));
      const channelDoc = await getDocs(q);
      setChannel(channelDoc.docs.at(0).data());
      const q2 = query(schoolsCollection, where("admin", "==", data.user_id));
      getDocs(q2).then((docs) => {
      setSchoolData(docs.docs.at(0).data());
    });
    }
  };
  const setView = async () => {
    const q = query(
      viewsColl,
      where("uid", "==", user.uid),
      where("video_id", "==", id)
    );
    const viewsDoc = await getDocs(q);
    if (viewsDoc.docs.length > 0) {
      setViewed(true);
    }
  };

  const getAd = async () => {
    const q = query(adColl);
    const adDoc = await getDocs(q);
    setAd(adDoc.docs.at(0).data());
  };

  const getUrl = () => {
    return adDone ? data.streamUrl : ad.video_url;
  };

  useEffect(() => {
    if (data && channel && ad) {
      setView();
      return;
    }
    getAd();
    getVideoChannel();
    setView();
  }, [adDone, data, channel, ad]);

  useEffect(() => {
    // setHeight(elementRef.current.offsetHeight);
  }, []);

  return data && channel && ad ? (
    <Page>
      {copied && (
        <Snackbar
          open={copied}
          autoHideDuration={6000}
          onClose={() => setCopied(false)}
        >
          <Alert
            onClose={() => setCopied(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Copied url!
          </Alert>
        </Snackbar>
      )}
      {console.log(`https://${getUrl()}`)}
      <div className="video-play-page">
        <div className="video-content-container">
          <div className="video-container" ref={elementRef}>
            <ReactPlayer
              // Disable download button
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              // Disable right click
              onContextMenu={(e) => e.preventDefault()}
              url={`https://${getUrl()}`}
              // url={'https://storage.googleapis.com/soma-output/7437e688-55f7-3464-c277-66657deb170a/manifest.m3u8'}
              controls={adDone}
              width="100%"
              height="100%"
              style={{
                width: "auto",
                height: "auto",
                zIndex: -100,
                backgroundSize: "cover",
                overflow: "hidden",
              }}
              onEnded={() => {
                adDone ? setInteracted(false) : setAdDone(true);
              }}
              playing={true}
              volume={0.1}
              onProgress={(play) => {
                if (play.played >= 0.8 && !viewed && adDone) {
                  updateWatch();
                  updateInteraction();
                } else if (play.played >= 0.8 && !interacted && adDone) {
                  updateInteraction();
                }
              }}
            />
            {console.log(getUrl())}
          </div>
          <div className="content-container">
            {/* <AdHeader ad={ad} /> */}
            <CardHeader
              avatar={
                <Avatar
                  sx={{ bgcolor: red[500] }}
                  aria-label="recipe"
                  src={schoolData?.logo_url}
                ></Avatar>
              }
              action={<Report videoId={data.id} uid={user.uid} />}
              title={data && data.title}
              subheader={data && data.date}
            />
            <div className="card-actions">
              <div className="stats">
                <Like
                  userData={user}
                  videoData={data}
                  id={id}
                  favorite={favorite}
                  setFavorite={setFavorite}
                />

                <ViewsCount id={id} viewed={interacted} />
                <LikesCount id={id} favorite={favorite} />
              </div>
              <div className="tools">
                <Follow creator_id={channel.uid} />
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={() => setCopied(true)}
                >
                  <Button variant="outlined" endIcon={<ShareRoundedIcon />}>
                    Copy url
                  </Button>
                </CopyToClipboard>
                <IconButton
                  onClick={() => setDiscussionVisible(!discussionVisible)}
                >
                  {discussionVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
            </div>
            <DiscussionReferences
              visibility={discussionVisible}
              discussion={data.description}
              references={data.references}
            />
          </div>
          <Comments data={{ uid: user.uid, video_id: id }} />
        </div>
        <SimilarContent />
      </div>
    </Page>
  ) : (
    <LoadingPage />
  );
};

export default VideoPlayPage;
