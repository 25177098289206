import React, { useEffect } from "react";
import "../../css/UploadPage.css";
import { useState } from "react";
import { storage, db } from "../../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { blue } from "@mui/material/colors";
import uuid from "react-uuid";
import { serverTimestamp } from "firebase/firestore";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";
import { Alert, AlertTitle, Collapse, TextField, Button } from "@mui/material";
import { auth } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Page from "../../components/Page";

const AdvertUpload = () => {
  const [videoUpload, setVideoUpload] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [videoPercent, setVideoPercent] = useState(0);
  const [successUpload, setSuccessUpload] = useState(false);
  const [title, setTitle] = useState("");
  const [country, setCountry] = useState("");
  const [user] = useAuthState(auth);
  const uploadVideo = () => {
    if (videoUpload) {
      const videoRef = ref(
        storage,
        `AdvertVideos/${videoUpload.name + uuid()}`
      );

      const videoUploadTask = uploadBytesResumable(videoRef, videoUpload);
      videoUploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setVideoPercent(percent);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(videoUploadTask.snapshot.ref).then((url) => {
            setVideoUrl(url);
          });
        }
      );
    } else {
      console.log("Not Run");
    }
  };
  useEffect(() => {
    if (videoUrl != "") {
      const data = {
        date_added: serverTimestamp(),
        description: description,
        title: title,
        user_id: user.uid,
        video_url: videoUrl,
        link: link,
        views: 0,
        likes: 0,
        country: country,
      };
      const videosColl = collection(db, "adverts");
      addDoc(videosColl, data)
        .then((docRef) => {})
        .catch((error) => {
          console.log(error);
        });

      setVideoUpload(null);
      setSuccessUpload(true);
      setDescription("");
      setVideoUrl("");
    }
  }, [videoPercent, videoUrl]);
  return (
    <Page>
      <div className="upload-content">
        <Collapse in={successUpload}>
          <Alert
            severity="success"
            onClose={() => {
              setSuccessUpload(false);
            }}
          >
            <AlertTitle>Success</AlertTitle>
            <strong>You have successfully uploaded a video</strong>
          </Alert>
        </Collapse>
        <div className="row-upload">
          <TextField
            id="standard-basic"
            label="Title"
            className="title-input"
            variant="standard"
            value={title}
            inputProps={{ maxLength: 40 }}
            onChange={(event) => setTitle(event.target.value)}
          />
          <CountryDropdown
            value={country}
            onChange={(country) => setCountry(country)}
          />
        </div>
        <div className="upload-content-container">
          <div className="video-upload-container upload-container">
            <h4>Upload Video</h4>
            {videoUpload ? (
              <CircularProgressWithLabel value={videoPercent} />
            ) : null}
            <label for="video-upload" class="custom-file-upload">
              Choose Video
            </label>
            <input
              name="video-input"
              id="video-upload"
              type="file"
              onChange={(event) => setVideoUpload(event.target.files[0])}
            />
          </div>
        </div>
        <TextField
          id="filled-multiline-static"
          label="Description"
          multiline
          rows={9}
          variant="filled"
          className="description-input"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <TextField
          sx={{ mt: 2 }}
          id="filled-multiline-static"
          label="Link"
          multiline
          variant="filled"
          rows={1}
          value={link}
          className="ref-input"
          onChange={(event) => setLink(event.target.value)}
        />
        <Button
          sx={{ backgroundColor: blue[400], mt: 3 }}
          variant="contained"
          onClick={uploadVideo}
        >
          Upload
        </Button>
      </div>
    </Page>
  );
};

export default AdvertUpload;
