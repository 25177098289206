import React, { useState } from "react";
import Header from "./Header";

const Page = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (openDrawer) {
      setOpenDrawer(!openDrawer);
    }
  };
  return (
    <div onKeyDown={toggleDrawer} onClick={toggleDrawer} style={{...props.style}}>
      <Header openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      {props.children}
    </div>
  );
};

export default Page;
