import React from "react";

const NoPage = () => {
  return (
    <div
      className="no-page"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div
        className="img-container"
        style={{ width: "30vw", height: "fit-content" }}
      >
        <img
          src="logo.svg"
          width={"100%"}
          // height={"100%"}
          style={{ objectFit: "cover" }}
        />
      </div>

      <h1>Page not Found</h1>
    </div>
  );
};

export default NoPage;
