import React from "react";
import Page from "../../components/Page";
import "../../css/SchoolHome.css";
import AccountData from "../../components/AccountCard";
import { Button } from "@mui/material";
import { auth, db } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams } from "react-router-dom";
import { getDoc, collection, doc } from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import InvitePopUp from "../../components/InvitePopUp";
import { useNavigate } from "react-router-dom";
import VideoCard from "../../components/VideoCard";
import { Avatar } from "@mui/material";
import SchoolPage from "../../components/SchoolPage";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileVideoCard from "../../components/MobileVideoCard";

const SchoolHome = () => {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const [schoolData, setSchoolData] = useState(null);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();

  const bigScreen = useMediaQuery("(min-width:1000px)");
  const mediumScreen = useMediaQuery("(min-width:800px)");



  useEffect(() => {
    if (schoolData !== null) return;
    const schoolRef = doc(db, "schools", id);
    const schoolVideos = [];
    getDoc(schoolRef).then((docs) => {
      docs.data().videos.map((videoID) => {
        const videoRef = doc(db, "school_videos", videoID);
        getDoc(videoRef).then((doc) => {
          schoolVideos.push({ ...doc.data(), id: videoID });
          setVideos(schoolVideos);
        });
      });
      setSchoolData({ ...docs.data() });
    });
  }, [schoolData]);

  return (
    <Page>
      {/* <SchoolPage id={id}> */}
      <div className="school-page">
        {schoolData?.approved ? <><div className="school-page-side-nav">
          <div className="name-logo">
            <Avatar
              src={schoolData && schoolData.logo_url}
              sx={{ marginRight: 4 }}
            ></Avatar>
            {schoolData && <h1>{schoolData.name}</h1>}
          </div>
          {schoolData && schoolData.tutors.indexOf(user.uid) >= 0 && (
            <div className="school-page-side-nav-btns">
              <Button
                variant="outlined"
                onClick={() => navigate(`/school/upload/${id}`)}
              >
                Upload
              </Button>
              {/* <Button variant="outlined" onClick={() => setInviteOpen(true)}>
                Invite
              </Button> */}
            </div>
          )}

          <h5>Members</h5>
          <div className="members">
            {schoolData &&
              schoolData.tutors.map((tutor_id) => (
                <AccountData
                  id={tutor_id}
                  key={tutor_id}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                />
              ))}
          </div>
        </div>
        <div className="school-page-content">
          <InvitePopUp
            inviteOpen={inviteOpen}
            setInviteOpen={setInviteOpen}
            id={id}
          />
          <div className="school-videos">
            {videos.map((video, index) => {
              return bigScreen ? 
                <VideoCard
                  key={index}
                  data={video}
                  isSchool={true}
                />
               : <MobileVideoCard key={index}
              data={video}
              isSchool={true} />
            })}
          </div>
        </div>
        </> : <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "20%"}}><h3>Wait for Approval</h3> <Button
                variant="outlined"
                onClick={() => navigate(`/`)}
              >
                Go Back
              </Button></div>
        }
      </div>
      {/* </SchoolPage> */}
    </Page>
  );
};

export default SchoolHome;
