import "../../css/LoginPage.css";
import React, { useEffect, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import validator from "validator";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const auth = getAuth();
  const resetPassword = () => {
    if (validator.isEmail(email)) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          alert("Password reset sent to your email");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    } else {
      alert("Invalid email");
    }
  };

  return (
    <div className="password-reset-page">
      <div className="password-reset-container">
        <img src="logo.svg" alt="2ution" width={"100%"} height={"35%"} />
        <TextField
          className="username"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          sx={{ padding: 0 }}
        />
        <Button
          variant="contained"
          sx={{ backgroundColor: "dodgerblue" }}
          className="log-in-btn"
          onClick={resetPassword}
        >
          Reset password
        </Button>
      </div>
    </div>
  );
};

export default PasswordReset;
