import React, { useState, useEffect } from "react";
import { query, getDocs, where, collection } from "firebase/firestore";
import { db } from "../config/firebase";
import { Avatar } from "@mui/material";

const AccountData = (props) => {
  const [data, setData] = useState(null);
  const usersCollection = collection(db, "users");
  const id = props.id;

  useEffect(() => {
    if (data) return;
    const q = query(usersCollection, where("uid", "==", id));
    getDocs(q).then((docs) => setData(docs.docs.at(0).data()));
  }, [data]);
  return (
    <div className="account-card" style={{ ...props.style }}>
      <Avatar src={data && data.photo_url}></Avatar>
      <span>{data && data.name}</span>
    </div>
  );
};

export default AccountData;
