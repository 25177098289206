import "../css/VideoPlayPage.css";
import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { db } from "../config/firebase";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { serverTimestamp } from "firebase/firestore";
import Comment from "./Comment";

const Comments = (propData) => {
  const [comment, setComment] = useState("");
  const [postedComment, setPostedComment] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const commentsCollection = collection(db, "comments");
  const addComment = () => {
    setPostedComment(true);
    const data = {
      comment: comment,
      date: serverTimestamp(),
      uid: propData.data.uid,
      video_id: propData.data.video_id,
    };
    addDoc(commentsCollection, data).then(() => {
      setComment("");
      setPostedComment(false);
    });
  };
  useEffect(() => {
    const q = query(
      commentsCollection,
      where("video_id", "==", propData.data.video_id)
    );
    let comments = [];
    getDocs(q).then((comment_docs) => {
      comment_docs.docs.map((comment_doc) => {
        comments.push(comment_doc.data());
      });
      setAllComments(comments);
    });
  }, [postedComment]);
  return (
    <>
      {allComments ? (
        <div className="comments-container">
          <h2>Comments</h2>
          <div className="comments">
            {allComments.map((comment, index) => (
              <Comment key={index} comment={comment} />
            ))}
          </div>
          <TextField
            id="outlined-multiline-static"
            label="Add a comment"
            multiline
            fullWidth
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
          <Button sx={{ mt: 2 }} variant="outlined" onClick={addComment}>
            Post
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default Comments;
