import "../css/VideoPlayPage.css";
import React from "react";
import MiniVideoCard from "../components/MiniVideoCard";
import { useState, useEffect } from "react";
import { db } from "../config/firebase";
import { getDocs, collection, query, limit } from "firebase/firestore";

const SimilarContent = (props) => {
  const [similarData, setSimilarData] = useState(null);
  const videosCollection = collection(db, "school_videos");

  const getVideos = async () => {
    try {
      const q = query(videosCollection, limit(4));
      const video_data = await getDocs(q);
      const dataDoc = video_data.docs.map((data_doc) => ({
        ...data_doc.data(),
        id: data_doc.id,
      }));
      setSimilarData(dataDoc);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getVideos();
  }, []);
  return (
    <div className="similar-content">
      <h2
        style={{ fontWeight: 400, marginBottom: 10 }}
        className="similar-content-title"
      >
        Similar content
      </h2>
      {similarData &&
        similarData.map((video, index) => (
          <MiniVideoCard
            key={index}
            title={video.title}
            img_url={video.thumbnail_url}
            creator_id={video.user_id}
            date={video.date_added}
            id={video.id}
            isPremium={video.isPremium}
            // setIDState={props.setIDState}
          />
        ))}
    </div>
  );
};

export default SimilarContent;
