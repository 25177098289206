import "../css/VideoPlayPage.css";
import React from "react";
import { useState, useEffect } from "react";
import { getDoc, collection, doc } from "firebase/firestore";
import { db } from "../config/firebase";

const ViewsCount = (props) => {
  const viewsColl = collection(db, "views");
  const id = props.id;
  const school = props.school;
  const [views_count, setViewsCount] = useState(0);
  const viewed = props.viewed;

  useEffect(() => {
    const viewsCount = async () => {
      const videoRef = doc(db, "school_videos", id);
      const videoDoc = await (await getDoc(videoRef)).data();
      setViewsCount(videoDoc.views);
    };
    viewsCount();
  }, [viewed]);

  return (
    <span className="views-count">
      {views_count}{" "}
      <span style={{ fontSize: "small" }}>
        {" "}
        {views_count === 1 ? "Interaction" : "Interactions"}
      </span>
    </span>
  );
};

export default ViewsCount;
