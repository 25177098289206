import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { query, getDocs, where, collection } from "firebase/firestore";
import { db, auth } from "../../config/firebase";
import CircularProgress from "@mui/material/CircularProgress";

const PrivateAdmin = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const usersCollection = collection(db, "users");
  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (user) {
    const q = query(usersCollection, where("uid", "==", user.uid));
    getDocs(q).then((docs) => {
      const data = docs.docs.at(0).data();
      if (!data.admin || data.admin === false) return <Navigate to="//" />;
    });
  }
  return children;
};

export default PrivateAdmin;
