import React, { useState, useEffect } from "react";
import "../css/VideoPlayPage.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../config/firebase";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import {
  query,
  getDocs,
  getDoc,
  where,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";

const Follow = (props) => {
  const [following, setFollowing] = useState(false);
  const [followingAccounts, setFollowingAccounts] = useState([]);
  const [creatorFollowAccounts, setCreatorFollowAccounts] = useState([]);
  const [userAccountId, setUserAccountId] = useState(null);
  const [creatorAccountId, setCreatorAccountId] = useState(null);
  const [user] = useAuthState(auth);
  const usersCollection = collection(db, "users");

  const unFollow = () => {
    var accounts = followingAccounts.filter((id) => id !== props.creator_id);

    setFollowingAccounts(accounts);
    const updateFields = {
      following: accounts,
    };
    const creatorDoc = doc(db, "users", creatorAccountId);
    const refDoc = doc(db, "users", userAccountId);
    getDoc(creatorDoc).then((doc) => {
      setCreatorFollowAccounts(doc.data().followers);
    });
    var creatorAccounts = creatorFollowAccounts.filter((id) => id !== user.uid);
    setCreatorFollowAccounts(creatorAccounts);

    updateDoc(refDoc, updateFields)
      .then(() => {
        setFollowing(false);
      })
      .then(() => {
        updateDoc(creatorDoc, { followers: creatorAccounts }).then(() => {
          setFollowing(false);
        });
      });
  };

  const follow = () => {
    var accounts = followingAccounts;
    accounts.push(props.creator_id);
    setFollowingAccounts(accounts);

    const updateFields = {
      following: followingAccounts,
    };

    const creatorDoc = doc(db, "users", creatorAccountId);
    getDoc(creatorDoc).then((doc) => {
      setCreatorFollowAccounts(doc.data().followers);
    });
    var creatorAccounts = creatorFollowAccounts.filter((id) => id !== user.uid);
    creatorAccounts.push(user.uid);
    setCreatorFollowAccounts(creatorAccounts);
    const refDoc = doc(db, "users", userAccountId);
    updateDoc(refDoc, updateFields)
      .then(() => {
        updateDoc(creatorDoc, { followers: creatorAccounts });
      })
      .then(() => {
        setFollowing(true);
      });
  };

  useEffect(() => {
    if (followingAccounts.length > 0) return;
    const q = query(usersCollection, where("uid", "==", user.uid));
    getDocs(q).then((doc) => {
      setFollowingAccounts(doc.docs.at(0).data().following);
      setFollowing(doc.docs.at(0).data().following.includes(props.creator_id));
      setUserAccountId(doc.docs.at(0).id);
    });
    const q2 = query(usersCollection, where("uid", "==", props.creator_id));
    getDocs(q2).then((doc) => {
      setCreatorAccountId(doc.docs.at(0).id);
    });
  }, [following]);
  return user.uid !== props.creator_id ? (
    following ? (
      <button
        className="follow-container  unfollow-container"
        onClick={unFollow}
      >
        <span>Unfollow</span>
        <RemoveOutlinedIcon />
      </button>
    ) : (
      <button className="follow-container" onClick={follow}>
        <span>Follow</span>
        <AddOutlinedIcon />
      </button>
    )
  ) : null;
};

export default Follow;
