const topics = [
  "Science",
  "Arts",
  "Mathematics",
  "IT",
  "History",
  "Geography",
  "Languages",
  "Agriculture",
  "Business",
  "Drawing & Design",
  "Life Skills",
  "Sports",
  "Blog",
  "Beauty",
];

export default topics;
