import "../../css/LoginPage.css";
import React, { useState } from "react";
import { CountryRegionData } from "react-country-region-selector";

import { useNavigate } from "react-router-dom";
import { blue } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  query,
  getDocs,
  where,
  collection,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db, auth } from "../../config/firebase";
import educationLevels from "../../data/educationLevels";
import Category from "../../components/Category";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@mui/material/TextField';

const CompleteProfile = () => {
  const [country, setCountry] = useState("");
  const [platform, setPlatform] = useState("student");
  const [user] = useAuthState(auth);
  const [educationLevel, setEducationLevel] = useState("");
  const [date, setDate] = useState(new Date("2000-01-01"));
  const navigate = useNavigate();

  const countries = [];
  CountryRegionData.map((data) => {
    countries.push(data.at(0));
  });



  const usersCollection = collection(db, "users");

  const updateProfile = () => {
    if (country === "" || educationLevel === "")
      return;
    const q = query(usersCollection, where("uid", "==", user.uid));
    getDocs(q)
      .then((docs) => {
        const ref = docs.docs.at(0).id;
        const updateFields = {
          country: country,
          dateOfBirth:date,
          platform: platform,
          educationLevel: educationLevel,
        };
        const refDoc = doc(db, "users", ref);
        updateDoc(refDoc, updateFields)
          .then((message) => {
            navigate("/");
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="complete-profile-page">
      <h1>Complete Your Profile</h1>
      <div className="complete-profile-container">
        <img src="logo.jpg" alt="2ution" width={"100%"} height={"35%"} />
        <Category
              category={country}
              setCategory={setCountry}
              topics={countries}
              style={{ width: "90%", borderColor: "grey" }}
              categoryTitle="Country Select"
            />
        <DatePicker showIcon selected={date} onChange={(date) => setDate(date)} customInput={<TextField id="outlined-basic" className="date-input" label="Date Of Birth" variant="outlined" />} />
        <Category
          category={educationLevel}
          setCategory={setEducationLevel}
          topics={educationLevels}
          style={{ width: "90%", borderColor: "grey" }}
          categoryTitle="Education level"
        />
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(event) => setPlatform(event.target.value)}
        >
          <FormControlLabel value="tutor" control={<Radio />} label="Tutor" />
          <FormControlLabel
            value="student"
            control={<Radio />}
            label="Student"
          />
        </RadioGroup>
        <Button
          variant="contained"
          sx={{ backgroundColor: blue[500] }}
          className="log-in-btn"
          onClick={updateProfile}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CompleteProfile;
