import React, { useState, useEffect } from "react";
import { Avatar, Button, TextField } from "@mui/material";
import Page from "../../components/Page";
import "../../css/SubscribePage.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { auth, db } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import CircularProgress from "@mui/material/CircularProgress";

const SubscriptionConfirm = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [user] = useAuthState(auth);
  const [success, setSuccess] = useState(null);

  const API_HOST = "https://api.2ution.com";

  const confirm_payment = async () => {
    const trxref = searchParams.get("trxref");
    const uid = user.uid;
    const url = `${API_HOST}/paystack_confirm?UID=${uid}&Txref=${trxref}`;
    const response = await fetch(url);
    const data = await response.json();

    if (data.status_code === 0) {
      setSuccess(true);
      setId(data.id);
    } else {
      setSuccess(false);
    }
  };
  useEffect(() => {
    confirm_payment();
  }, []);

  return (
    <Page>
      <div className="subscribe-page">
        {success === null ? (
          <CircularProgress />
        ) : success ? (
          <>
            <h3 style={{ color: "green" }}>Subscription successful</h3>
            <Button
              variant="outlined"
              className="subscribe-btn"
              onClick={() => navigate(`/school/${id}`)}
            >
              Continue
            </Button>
          </>
        ) : (
          <>
            <h3 style={{ color: "red" }}>Subscription failed</h3>
            <Button
              variant="outlined"
              className="subscribe-btn"
              onClick={() => navigate(`/`)}
            >
              Return
            </Button>
          </>
        )}
      </div>
    </Page>
  );
};

export default SubscriptionConfirm;
