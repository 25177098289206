import "../../css/HomePage.css";
import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../config/firebase";
import VideoCard from "../../components/VideoCard";

const Watched = () => {
  const [user] = useAuthState(auth);
  const [watchedRef, setWatchedRef] = useState([]);
  const [watched, setWatched] = useState([]);
  const [set, setSet] = useState(false);
  const viewsCollection = collection(db, "views");

  useEffect(() => {
    const getVideos = () => {
      if (watchedRef.length > 0) {
        var videos = [];
        watchedRef.map((ref) => {
          var viewedDoc = doc(db, "videos", ref.video_id);
          getDoc(viewedDoc).then((video) =>
            videos.push({ ...video.data(), id: video.id })
          );
        });
        setWatched(videos);
        setSet(true);
      }
      return;
    };

    const getRef = () => {
      if (user) {
        const q = query(viewsCollection, where("uid", "==", user.uid));
        var ref = [];
        getDocs(q)
          .then((docs) => {
            docs.docs.map((doc) => ref.push(doc.data()));
          })
          .then(() => setWatchedRef(ref));
      }
      return;
    };
    if (!set) {
      getRef();
      // getVideos();
    }
  }, [watched, watchedRef]);
  return (
    <Page>
      <h3>Recently Watched</h3>
      <div className="videos">
        {watched.map((video, index) => (
          <VideoCard key={index} data={video} />
        ))}
      </div>
    </Page>
  );
};

export default Watched;
