import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Category = (props) => {
  const category = props.category;
  const setCategory = props.setCategory;
  const topics = props.topics;
  return (
    <FormControl fullWidth style={{ ...props.style }}>
      <InputLabel id="category-select-label">{props.categoryTitle}</InputLabel>
      <Select
        labelId="category-select-label"
        value={category}
        label="category-select"
        onChange={(event) => setCategory(event.target.value)}
      >
        {topics.map((topic, index) => {
          return (
            <MenuItem key={index} value={topic}>
              {topic}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Category;
