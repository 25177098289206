import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import "../css/SubscribePage.css";
import Iframe from "react-iframe";
import CircularProgress from "@mui/material/CircularProgress";

const Paystack = (props) => {
  const id = props.id;
  const user = props.user;
  const schoolData = props.schoolData;

  //pesapal
  const [redirectUrl, setRedirectUrl] = useState("");

  const API_HOST = "https://api.2ution.com";

  useEffect(() => {
    const getRedirectUrl = async () => {
      const url = `${API_HOST}/paystack_subscribe?Amount=${schoolData.amount}&Email=${user.email}&Uid=${user.uid}&Institution=${id}`;
      const response = await fetch(url);
      const data = await response.json();
      window.location.replace(data.url);
    };
    getRedirectUrl();
  }, []);

  return (
    <div className="subscribe-page">
      {/* <div className="subscribe-container"> */}
      <div className="content-iframe">
        <div className="name-logo">
          <Avatar
            src={schoolData && schoolData.logo_url}
            sx={{ marginRight: 4 }}
          ></Avatar>
          <span>{schoolData && schoolData.name}</span>
          <h4>
            You will be required to pay Kshs. {schoolData && schoolData.amount}
          </h4>
        </div>
        {redirectUrl === "" &
          <CircularProgress />}
      </div>
    </div>
  );
};

export default Paystack;
