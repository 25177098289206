import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { red, blue } from "@mui/material/colors";
import { Badge } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../config/firebase";
import Skeleton from "@mui/material/Skeleton";
import "../css/HomePage.css";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const VideoCard = (video) => {
  const navigate = useNavigate();
  const isSchool = video.isSchool;
  const [userData, setUserData] = useState(null);
  const [schoolData, setSchoolData] = useState(null);
  const [views_count, setViewsCount] = useState(0);
  const [likes_count, setLikesCount] = useState(0);
  const usersCollection = collection(db, "users");
  const schoolsCollection = collection(db, "schools");
  const viewsColl = collection(db, "views");
  const likesColl = collection(db, "likes");


  const handleCreatorProfile = () => {
    navigate(`/channel/${userData.uid}`);
  };

  useEffect(() => {
    const user_id = video.data.user_id;
    setViewsCount(video.data.views);
    const q = query(schoolsCollection, where("admin", "==", user_id));
    getDocs(q).then((docs) => {
      setSchoolData(docs.docs.at(0).data());
    });

    const likesCount = () => {
      const q3 = query(likesColl, where("video_id", "==", video.data.id));
      getDocs(q3).then((docs) => {
        if (docs) {
          setLikesCount(docs.docs.length);
        }
      });
    };
    likesCount();
    // viewsCount();
  }, []);

  const getTime = (created_time) => {
    const date = new Date(created_time.seconds * 1000);
    return date.toDateString();
  };

  return schoolData ? (
    <div className="video-card" style={{ ...video.style }}>
      <div className="thumbnail-container" onClick={() => {
          if (isSchool) {
            navigate(`/school/play/${video.data.id}`, {
              state: { data: video.data, channel: schoolData },
            });
          } else {
            navigate(`/play/${video.data.id}`, {
              state: { data: video.data, channel: schoolData },
            });
          }
        }}>
      <img
        src={video.data.thumbnail_url}
        className="video-thumbnail"
        />
        {video.data.isPremium && <div className="thumbnail-icon-container">
            {/* <LockOutlinedIcon style={{border: "solid 1px black", background: "white", borderRadius: "50%", padding: "5px"}} color="secondary" /> */}
            <span style={{color:"white", backgroundColor: "forestgreen", borderRadius: "10px", paddingLeft: "5px", paddingRight: "5px", paddingBottom: "2px"}}>premium</span>
        </div>}
        

      </div>
      <div className="card-details">
        <span className="video-title">{video.data.title}</span>
        <div className="views-likes">
          <span className="card-views">
            {views_count === 1
              ? `${views_count} Interaction`
              : `${views_count} Interactions`}
          </span>
          <span className="card-likes">
            {views_count === 1 ? `${likes_count} Like` : `${likes_count} Likes`}
          </span>
        </div>
        <div className="creator-profile">
          <button
            className="creator-profile-btn"
            onClick={handleCreatorProfile}
          >
            <Avatar
              sx={{
                bgcolor: red[500],
                maxWidth: 30,
                maxHeight: 30,
                marginRight: 1,
                marginTop: 1,
                zIndex: 1,
              }}
              src={schoolData.logo_url}
            ></Avatar>
          </button>
          <div className="name-date">
            <span className="creator-name">{schoolData.name}</span>

            <span className="create-date">
              {video.data.date_added && getTime(video.data.date_added)}
            </span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="video-card">
      <Skeleton
        variant="rounded"
        className="video-thumbnail"
        sx={{
          minHeight: 180,
          borderRadius: 5,
          minWidth: "250px",
          ...video.style,
        }}
      />
    </div>
  );
};

export default VideoCard;
