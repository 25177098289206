import "../css/Header.css";
import React from "react";
import MiniProfile from "./MiniProfile";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useNavigate } from "react-router-dom";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { Button } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

const Header = (props) => {
  const navigate = useNavigate();
  const setOpenDrawer = props.setOpenDrawer;
  const openDrawer = props.openDrawer;
  const [displayInput, setDisplayInput] = useState(false);
  const [focus, setFocus] = useState(false);

  const bigScreen = useMediaQuery("(min-width:600px)");
  useEffect(() => {}, [displayInput, focus]);

  const search = () => {};
  return (
    <>
      <SwipeableDrawer
        anchor={"left"}
        open={openDrawer}
        // onClose={() => {
        //   setOpenDrawer(false);
        // }}
        // onOpen={() => {
        //   setOpenDrawer(true);
        // }}
      >
        <List>
          <ListItem>
            <ListItemButton href="/">
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                sx={{ paddingLeft: 2, paddingRight: 5 }}
              />
            </ListItemButton>
          </ListItem>

          {/* <ListItem>
            <ListItemButton onClick={() => navigate("/upload")}>
              <ListItemIcon>
                <FileUploadOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Upload Content"
                sx={{ paddingLeft: 2, paddingRight: 5 }}
              />
            </ListItemButton>
          </ListItem> */}

          <ListItem>
            <ListItemButton disabled>
              <ListItemIcon>
                <InfoOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Notifications"
                sx={{ paddingLeft: 2, paddingRight: 5 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton onClick={() => navigate("/about")}>
              <ListItemIcon>
                <InfoOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary="About us"
                sx={{ paddingLeft: 2, paddingRight: 5 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </SwipeableDrawer>
      {displayInput ? (
        <header className="header">
          <IconButton
            className="side-nav-menu"
            onClick={() => setOpenDrawer(true)}
            sx={{ aspectRatio: 1 }}
          >
            <MenuIcon sx={{ fontSize: 35, color: "dodgerblue" }} />
          </IconButton>
          <div className="header-contents">
            <div className={focus ? "search-bar-focus" : "search-bar"}>
              <input
                className="search-input"
                placeholder="Search"
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
              ></input>
              <IconButton
                onClick={() => {
                  setDisplayInput(!displayInput);
                  search();
                }}
              >
                <SearchOutlinedIcon sx={{ color: "grey" }} />
              </IconButton>
            </div>
            <MiniProfile />
          </div>
        </header>
      ) : (
        <header className="header">
          <IconButton
            className="side-nav-menu"
            onClick={() => {
              setOpenDrawer(true);
            }}
            sx={{ aspectRatio: 1 }}
          >
            <MenuIcon sx={{ fontSize: 35, color: "dodgerblue" }} />
          </IconButton>
          <div className="header-contents">
            {bigScreen ? (
              <>
                <img
                  src="/logo.svg"
                  alt="2ution"
                  width={"13%"}
                  height={"100%"}
                  onClick={() => {
                    navigate("/");
                  }}
                />
                <div className={focus ? "search-bar-focus" : "search-bar"}>
                  <input
                    className="search-input"
                    placeholder="Search"
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                  ></input>
                  <IconButton onClick={search}>
                    <SearchOutlinedIcon sx={{ color: "grey" }} />
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <img
                  src="/logo.svg"
                  alt="2ution"
                  width={"40%"}
                  height={"100%"}
                  onClick={() => {
                    navigate("/");
                  }}
                />
                <IconButton onClick={() => setDisplayInput(!displayInput)}>
                  <SearchOutlinedIcon sx={{ color: "grey" }} />
                </IconButton>
              </>
            )}

            <Button
              variant="outlined"
              endIcon={<VideocamOutlinedIcon fontSize="large" />}
              disabled
            >
              LIVE
            </Button>

            <MiniProfile />
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
