import "../css/VideoPlayPage.css";
import React from "react";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { db } from "../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const InvitePopUp = (props) => {
  const setInviteOpen = props.setInviteOpen;
  const inviteOpen = props.inviteOpen;
  const id = props.id;
  const handleClickOpen = () => {
    setInviteOpen(true);
  };

  const handleClose = () => {
    setInviteOpen(false);
  };
  useEffect(() => {}, [inviteOpen]);
  return (
    <div>
      <Dialog open={inviteOpen}>
        <DialogTitle>Invite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Copy link below and share it to the tutor you want to invite.
          </DialogContentText>
          <DialogContentText sx={{ marginTop: 2, fontWeight: 800 }}>
            {window.location.hostname + `/school/join/${id}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InvitePopUp;
