import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { grey, lightBlue } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import { useAuthState } from "react-firebase-hooks/auth";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { logout } from "../config/firebase";
import { query, getDocs, where, collection } from "firebase/firestore";
import { db, auth } from "../config/firebase";

const MiniProfile = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [userProfile, setUserProfile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [channel, setChannel] = useState(null);
  const usersCollection = collection(db, "users");
  const open = Boolean(anchorEl);
  const schoolsCollection = collection(db, "schools");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {

    const getMyChannel = async () => {
      const q2 = query(schoolsCollection, where("admin", "==", user.uid));
        const docs = await getDocs(q2)
        console.log(docs.docs.at(0).data())
        if (docs.docs.length > 0) {
          setChannel(docs.docs.at(0).id);
        }
    }
    if (user) {
      const q = query(usersCollection, where("uid", "==", user.uid));
      getDocs(q)
        .then((docs) => {
          setUserProfile(docs.docs.at(0).data());
        })
        .catch((err) => console.log(err));
        getMyChannel();
    }
  }, [user]);

  return (
    <>
      {user ? (
        <button className="mini-profile" onClick={handleClick}>
          <Avatar
            sx={{
              bgcolor: user ? lightBlue[800] : grey[800],
              maxWidth: 40,
              maxHeight: 40,
              margin: 0,
            }}
            aria-label="Name"
            src={userProfile && userProfile.photo_url}
          ></Avatar>
        </button>
      ) : (
        <Button
          variant="outlined"
          sx={{
            padding: 0,
            paddingLeft: 1,
            paddingRight: 1,
            fontSize: "0.7rem",
            flexDirection: "column",
          }}
          onClick={() => navigate("/login")}
        >
          Log In
        </Button>
      )}

      <Menu
        sx={{
          color: lightBlue[800],
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{ width: 300 }}
          onClick={() => {
            handleClose();
            navigate("/profile");
          }}
        >
          Profile
        </MenuItem>
        {userProfile && channel ? (
          <div>
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(`/school/${channel}`);
              }}
            >
              My Channel
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                handleClose();
                navigate("/upload");
              }}
            >
              Upload
            </MenuItem> */}
          </div>
        ) : null}
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/favorites");
          }}
        >
          My Favorites
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/viewed");
          }}
        >
          Recently Watched
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            user ? logout() : navigate("/login");
          }}
        >
          {user ? "Log out" : "Log in"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default MiniProfile;
