import "../../css/LoginPage.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { blue } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import GoogleIcon from "@mui/icons-material/Google";
import validator from "validator";

const SigninPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const authenticate = () => {
    if (password.length < 8 || confirmPassword.length < 8) {
      alert("Password should have a minimum length of 8 characters");
      return;
    }
    if (confirmPassword === password) {
      if (validator.isEmail(email)) {
        registerWithEmailAndPassword(email, email, password);
      } else {
        alert("Invalid email");
        return;
      }
    } else {
      alert("Passwords do not match");
      setPassword("");
      setConfirmPassword("");
    }

    console.log(user);
  };
  if (user) {
    navigate("/");
  }
  useEffect(() => {}, [user]);
  return (
    <div className="login-page">
      <div className="login-container sigin-container">
        <img src="/logo.svg" alt="2ution" width={"100%"} height={"35%"} />
        {/* <TextField
          className="username"
          label="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <span style={{fontSize: "small", width: "80%",
    marginLeft: "5%",
    textAlign: "left",
}}>Password must be at least 8 characters</span>
        <TextField
          className="password"
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          className="confirm-password"
          label="Confirm Password"
          type="password"
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          variant="contained"
          className="sign-up-btn"
          onClick={authenticate}
          sx={{ backgroundColor: blue[500] }}
        >
          SIGN UP
        </Button> */}
        <Button
          variant="outlined"
          className="log-in-btn"
          startIcon={<GoogleIcon color="primary" />}
          onClick={async () => await signInWithGoogle()}
        >
          Sign Up with Google
        </Button>
        {/* <button className="btn1 log-in-btn" >
          Sign Up
        </button> */}
      </div>
    </div>
  );
};

export default SigninPage;
