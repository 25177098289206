import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Page from "../../components/Page";
import { Button, TextField, Alert, Snackbar } from "@mui/material";
import "../../css/NewSchoolPage.css";
import { db, auth, storage } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, serverTimestamp, updateDoc, getDocs, query, where } from "firebase/firestore";
import uuid from "react-uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { CountryRegionData } from "react-country-region-selector";
import Category from "../../components/Category";
import subscriptionAmounts from "../../data/subscriptionAmounts";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import topics from "../../data/topics";
import {  } from "@mui/material";


const NewSchool = () => {
  const [schoolName, setSchoolName] = useState("");
  const [logoUpload, setLogoUpload] = useState(null);
  const [logoPath, setLogoPath] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");
  const [country, setCountry] = useState("");
  const [amount, setAmount] = useState(1);
  const [success, setSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoUploadPercent, setLogoUploadPercent] = useState(0);
  const colRef = collection(db, "users");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();



  const [state, setState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [user] = useAuthState(auth);

  var countries = [];
  CountryRegionData.map((countryData) => {
    countries.push(countryData.at(0));
  });

  const handleChange = (event, index) => {
    const _checked = [...state];
    _checked[index] = event.target.checked;
    setState(_checked);
  };

  const schoolsCollection = collection(db, "schools");

  const uploadLogo = () => {
    if (logoUpload && schoolName !== "" && amount && country !== "") {
      setLogoPath(`schools/logos/${uuid() + logoUpload.name}`);
      const logoRef = ref(
        storage,
        `schools/logos//${uuid() + logoUpload.name}`
      );

      const logoUploadTask = uploadBytesResumable(logoRef, logoUpload);
      logoUploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setLogoUploadPercent(percent);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(logoUploadTask.snapshot.ref).then((url) => {
            setLogoUrl(url);
          });
        }
      );
    } else return;
  };

  const addSchool = async () => {
    const subjects = [];
    state.map((item, index) => {
      if (item) {
        subjects.push(topics[index]);
      }
    });
    const ref = await addDoc(schoolsCollection, {
      admin: user.uid,
      name: schoolName,
      date_added: serverTimestamp(),
      tutors: [user.uid],
      logo_url: logoUrl,
      path: logoPath,
      amount: amount,
      country: country,
      subjects: subjects,
      videos: [],
      approved: false,
    });
    const q = query(colRef, where("uid", "==", user.uid));
    getDocs(q) // or getDocs(q) if you created a query
  .then((querySnapshot) => {
    const doc = querySnapshot.docs[0]; // Array of DocumentSnapshot objects
    updateDoc(doc, {school: ref.id})
  })
  .catch((error) => {
    console.error("Error getting documents:", error);
  });
    setSuccess(true);
    navigate(`/`);
  };

  useEffect(() => {
    if (logoUploadPercent == 100 && logoUrl !== "") {
      addSchool();
    }
  }, [logoUploadPercent, logoUrl]);

  return (
    <Page>
      <div className="new-school-page">
      <Snackbar
          open={success}
          autoHideDuration={1500}
          onClose={handleClose}
        >
          <Alert
            severity="success"
            onClose={() => {
              setSuccess(false);
            }}
          >
            <strong>You have successfully requested to start a school</strong>
          </Alert>
        </Snackbar>
        <div className="logo-upload-container">
          {logoUpload ? <h4>{logoUpload.name}</h4> : <h4>Upload Logo</h4>}
          <label for="logo-upload" class="custom-file-upload">
            Choose Logo
          </label>
          <input
            name="logo-input"
            id="logo-upload"
            type="file"
            onChange={(event) => setLogoUpload(event.target.files[0])}
          />
        </div>
        {logoUpload && <CircularProgressWithLabel value={logoUploadPercent} />}
        <TextField
          id="standard-basic"
          label="School Name"
          className="school-name-input"
          variant="standard"
          value={schoolName}
          inputProps={{ maxLength: 40 }}
          onChange={(event) => setSchoolName(event.target.value)}
        />
        <div className="new-school-inputs">
          <Category
            category={country}
            setCategory={setCountry}
            topics={countries}
            style={{ width: "40%", borderColor: "grey" }}
            categoryTitle="Country Select"
          />
          <Category
            category={amount}
            setCategory={setAmount}
            topics={subscriptionAmounts}
            style={{ width: "40%", borderColor: "grey" }}
            categoryTitle="subscription amount"
          />
        </div>
        <div className="subject-checkboxes-container">
          <h3>Select all subjects that will be taught in your channel</h3>
          <FormGroup
            sx={{ display: "flex", flexDirection: "row" }}
            className="subject-checkboxes"
          >
            {topics.map((topic, index) => (
              <FormControlLabel
                checked={state[index]}
                onChange={(event) => handleChange(event, index)}
                key={index}
                control={<Checkbox />}
                label={topic}
                sx={{ width: "30%" }}
              />
            ))}
          </FormGroup>
        </div>
        <Button variant="outlined" onClick={uploadLogo}>
          Submit request
        </Button>
      </div>
    </Page>
  );
};

export default NewSchool;
