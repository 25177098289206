import React, { useState, useEffect } from "react";
import { Avatar, Button, TextField, Alert, Snackbar } from "@mui/material";
import "../css/SubscribePage.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const Mpesa = (props) => {
  const id = props.id;
  const user = props.user;
  const schoolData = props.schoolData;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [stkPushSent, setStkPushSent] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [CheckoutRequestID, setCheckoutRequestID] = useState("");
  const [MerchantRequestID, setMerchantRequestID] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const API_HOST = "https://api.2ution.com";

  // useEffect(() => {}, [stkPushSent]);
  useEffect(() => {
    user.getIdToken().then((token) => {
      fetch(`${API_HOST}/subscribe`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    });
  }, []);

  const subscribe_mpesa = async (amount) => {
    setPressed(true);
    if (phoneNumber.length !== 12 || phoneNumber.slice(0, 3) !== "254") {
      alert("Invalid phone Number. Use the format 254XXXXXXXXX");
      return;
    }
    const response = await fetch(`${API_HOST}/subscribe`, {
      method: "post",
      headers: { Authorization: `Bearer ${user.getIdToken()}` },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
        schoolId: id,
        amount: "10",
      }),
      credentials: "same-origin",
    });

    const data = JSON.parse(await response.json());
    setCheckoutRequestID(data.CheckoutRequestID);
    setMerchantRequestID(data.MerchantRequestID);
    setStkPushSent(true);
    setPressed(false);
  };

  const confirmPayment = async () => {
    setPressed(true);
    user.getIdToken().then((token) => {
      fetch(`${API_HOST}/confirm_payment`, {
        headers: { Authorization: `Bearer ${token}` },
      })
    });

    const post_data = {
      Uid: user.uid,
      CheckoutRequestID: CheckoutRequestID,
      Institution: id,
      MerchantRequestID: MerchantRequestID,
    };
    const response = await fetch(`${API_HOST}/confirm_payment`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${user.getIdToken()}`,
      },
      body: JSON.stringify(post_data),
      credentials: "same-origin",
    });
    const data = JSON.parse(await response.json());
    if (data.ResponseCode) {
      setSuccess(true);
    }
    setPressed(false);
  };
  const handleClose = () => {
    if (success) {
      navigate(`/school/${id}`);
    } else {
      navigate("/");
    }
  };

  return (
    <div className="subscribe-page">
      <Snackbar open={success} autoHideDuration={1500} onClose={handleClose}>
        <Alert
          severity="success"
          onClose={() => {
            setSuccess(false);
          }}
        >
          <strong>You have successfully subscribed</strong>
        </Alert>
      </Snackbar>
      <div className="subscribe-page-content">
        <div className="name-logo">
          <Avatar
            src={schoolData && schoolData.logo_url}
            sx={{ marginRight: 4 }}
          ></Avatar>
          <span>{schoolData && schoolData.name}</span>
        </div>
        <h4>
          You will be required to pay Kshs.
          {schoolData && `${schoolData.amount}`}
        </h4>

        {/* </div> */}
        <TextField
          id="standard-basic"
          label="Safaricom phone number"
          className="phone-number-input"
          variant="standard"
          value={phoneNumber}
          inputProps={{ maxLength: 12 }}
          onChange={(event) => setPhoneNumber(event.target.value)}
          placeholder={"254XXXXXXXXX"}
        />
        {stkPushSent ? (
          <>
            <span>Mpesa prompt sent</span>
            {pressed ? (
              <CircularProgress />
            ) : (
              <Button
                variant="outlined"
                className="confirm-btn"
                onClick={confirmPayment}
              >
                Confirm Payment
              </Button>
            )}
          </>
        ) : (
          <>
            <span>
              You will receive an Mpesa prompt to pay Ksh.
              {schoolData && schoolData.amount}
            </span>
            {pressed ? (
              <CircularProgress />
            ) : (
              <Button
                variant="outlined"
                className="subscribe-btn"
                onClick={() => subscribe_mpesa(schoolData.amount)}
              >
                Subscribe
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Mpesa;
