import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdvertUpload from "./AdvertUpload";
import NoPage from "../NoPage";
import PrivateAdmin from "./PrivateAdmin";

const AdminRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="advert-upload"
          element={
            <PrivateAdmin>
              <AdvertUpload />
            </PrivateAdmin>
          }
        />
        <Route
          path="*"
          element={
            <PrivateAdmin>
              <NoPage />
            </PrivateAdmin>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AdminRouter;
