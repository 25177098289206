import "../css/HomePage.css";
import VideoCard from "./VideoCard";
import { db } from "../config/firebase";
import React, { useState, useEffect, useRef } from "react";
import Channels from "./Channels";
import { getDocs, collection, query, where, limit } from "firebase/firestore";
import Channel from "./Channel";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileVideoCard from "./MobileVideoCard"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Subject = (props) => {
  const topic = props.topic;
  const user = props.user;
  const [data, setData] = useState(null);
  const [allSchools, setAllSchools] = useState([]);
  const [mySubscriptions, setMySubscriptions] = useState([]);
  const schoolsCollection = collection(db, "schools");
  const subscriptionsCollection = collection(db, "subscriptions");
  const bigScreen = useMediaQuery("(min-width:1000px)");
  const mediumScreen = useMediaQuery("(min-width:800px)");
  const scrollRef = useRef(null);

  const scroll = () => {
    // scrollRef.current.scrollLeft = 200;
    console.log(scrollRef.current)
  };

  // const scroll = (scrollOffset) => {
  //   console.log(scrollOffset)
  //   console.log(scrollRef.current.scrollLeft)
  //   scrollRef.current.scrollLeft += 10;
  //   console.log(scrollRef.current.scrollLeft)
  //   console.log(scrollRef)
  // };


  const videosCollection = collection(db, "school_videos");

  useEffect(() => {
    const q = query(videosCollection, where("category", "==", topic), limit(6));
    var videos = [];
    getDocs(q)
      .then((dataDocs) => {
        dataDocs.docs.map((data_doc) => {
          videos.push({
            ...data_doc.data(),
            id: data_doc.id,
          });
        });
      })
      .then(() => {
        setData({ category: topic, data: videos });
      });
  }, []);

  useEffect(() => {
    // if (allSchools.length > 0 || mySubscriptions.length > 0) return;
    if (user) {
      const q = query(subscriptionsCollection, where("uid", "==", user.uid));
      var school_data = [];
      getDocs(q).then((docs) => {
        docs.docs.map((doc) => school_data.push(doc.data().institution));
      });
    }

    const q2 = query(
      schoolsCollection,
      where("subjects", "array-contains", topic)
    );
    getDocs(q2)
      .then((docs) => {
        const allData = [];
        const subscribedData = [];
        docs.docs.filter(doc => doc.data()?.approved).map((doc) => {
          if (
            user &&
            (school_data.indexOf(doc.id) >= 0 ||
              doc.data().tutors.indexOf(user.uid) >= 0)
          ) {
            subscribedData.push({ ...doc.data(), id: doc.id });
          } else {
            allData.push({ ...doc.data(), id: doc.id });
          }
        });
        setAllSchools(allData);
        setMySubscriptions(subscribedData);
      })
      .catch((err) => console.log(err));
  }, [user]);

  return (
    <div className="row" key={topic}>
      <div className="topic-name">
        <h3>{topic}</h3>
      </div>
      <div className="subject-channels-container">
        {mySubscriptions &&
          mySubscriptions.map((data) => (
            <Channel
              key={data.id}
              data={data}
              locked={false}
              noVideos={data.videos.length}
            />
          ))}
        {allSchools &&
          allSchools.map((data) => (
            <Channel
              key={data.id}
              data={data}
              locked={true}
              noVideos={data.videos.length}
            />
          ))}
      </div>
      { bigScreen ?
      <div className="videos">
        {data &&
          data.data.map((video, i) => <VideoCard key={i} data={video} /> )}
      </div> :
       <div ref={scrollRef} onScroll={()=> console.log(scrollRef.current)} className="mobile-videos">
       {data &&
         data.data.map((video, i) => <MobileVideoCard  key={i} data={video} /> )}
         <button className="scroll-btn scroll-left" onClick={scroll}><ArrowBackIcon sx={{ color: "dodgerblue" }} /></button>
        <button className="scroll-btn scroll-right" onClick={scroll}><ArrowForwardIcon sx={{ color: "dodgerblue" }} /></button>
     </div> }

    </div>
  );
};

export default Subject;
