import "../css/VideoPlayPage.css";
import React from "react";
import { useState, useEffect } from "react";
import { db } from "../config/firebase";
import { query, getDocs, where, collection } from "firebase/firestore";

const LikesCount = (props) => {
  const favorite = props.favorite;
  const id = props.id;
  const [likes_count, setLikesCount] = useState(0);
  const likesColl = collection(db, "likes");

  const likesCount = () => {
    const q = query(likesColl, where("video_id", "==", id));
    getDocs(q).then((docs) => {
      if (docs) {
        setLikesCount(docs.docs.length);
      }
    });
  };
  useEffect(() => {
    likesCount();
  }, [favorite]);
  return (
    <span className="likes-count">
      {likes_count}{" "}
      <span style={{ fontSize: "small" }}>
        {likes_count === 1 ? "Like" : "Likes"}
      </span>
    </span>
  );
};

export default LikesCount;
