import "../css/VideoPlayPage.css";
import React from "react";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { db } from "../config/firebase";
import {
  collection,
  getDocs,
  doc,
  query,
  where,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";

const Like = (props) => {
  const user = props.userData;
  const vid = props.id;
  const creator_id = props.videoData.user_id;
  const setFavorite = props.setFavorite;
  const favorite = props.favorite;
  const [likeRef, setLikeRef] = useState("");
  const likesCollection = collection(db, "likes");

  useEffect(() => {
    const setLikes = async () => {
      const q = query(
        likesCollection,
        where("video_id", "==", vid),
        where("uid", "==", user.uid)
      );
      getDocs(q).then((docs) => {
        if (docs.docs.length > 0) {
          setFavorite(true);
          setLikeRef(docs.docs.at(0).id);
        }
      });
    };
    setLikes();
  }, []);
  const addLike = async () => {
    if (!favorite) {
      const ref = await addDoc(likesCollection, {
        uid: user.uid,
        video_id: vid,
        date: serverTimestamp(),
        creator: creator_id,
      });

      setLikeRef(ref.id);
      setFavorite(true);
    } else {
      const likeDoc = doc(db, "likes", likeRef);
      await deleteDoc(likeDoc);
      setFavorite(false);
    }
  };
  return (
    <IconButton aria-label="add to favorites" onClick={addLike}>
      {favorite ? <FavoriteIcon sx={{ color: red[800] }} /> : <FavoriteIcon />}
    </IconButton>
  );
};

export default Like;
