import React, { useState, useEffect } from "react";
import { Avatar, Button } from "@mui/material";
import Page from "../../components/Page";
import "../../css/SubscribePage.css";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { auth, db } from "../../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Mpesa from "../../components/Mpesa";
import Paystack from "../../components/Paystack";

const SubscribePage = () => {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const [schoolData, setSchoolData] = useState(null);
  const [proceed, setProceed] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("other");

  const getSchoolData = async () => {
    const schoolRef = doc(db, "schools", id);
    const data = await (await getDoc(schoolRef)).data();
    setSchoolData(data);
  };
  useEffect(() => {
    getSchoolData();
  }, []);

  async function subscribe() {
    setProceed(true);
  }

  return (
    <Page>
      <div className="subscribe-page">
        {proceed ? (
          paymentMethod === "mpesa" ? (
            <Mpesa user={user} schoolData={schoolData} id={id} />
          ) : (
            <Paystack user={user} schoolData={schoolData} id={id} />
          )
        ) : (
          <div className="subscribe-page-content">
            <div className="name-logo">
              <Avatar
                src={schoolData && schoolData.logo_url}
                sx={{ marginRight: 4 }}
              ></Avatar>
              <span>{schoolData && schoolData.name}</span>
            </div>

            <h4>
              You will be required to pay Kshs.{" "}
              {schoolData && schoolData.amount}
            </h4>
            {/* <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event) => setPaymentMethod(event.target.value)}
            >
              <FormControlLabel
                value="mpesa"
                control={<Radio />}
                label="Mpesa"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Airtel Money / Card"
              />
            </RadioGroup> */}
            <Button
              variant="outlined"
              className="subscribe-btn"
              // onClick={() =>
              //   subscribe(
              //     schoolData.amount,
              //     `Subcription to ${schoolData.name}`
              //   )
              // }
              onClick={subscribe}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    </Page>
  );
};

export default SubscribePage;
