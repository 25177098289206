import React, { useEffect } from "react";
import Upload from "../../components/Upload";
import { useParams } from "react-router-dom";

const SchoolUpload = () => {
  const { id } = useParams();

  return <Upload schoolUpload={true} id={id} />;
};

export default SchoolUpload;
