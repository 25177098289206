import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import CircularProgress from "@mui/material/CircularProgress";

const PrivateRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  if (user) {
    return children;
  } else if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return <Navigate to="/login" />;
};

export default PrivateRoute;
