import React from "react";
import Page from "../../components/Page";
import { Button } from "@mui/material";
import "../../css/About.css";
import CopyrightIcon from "@mui/icons-material/Copyright";
import about from "../../data/about";

const About = () => {
  return (
    <Page>
      <div className="about-page">
        <div className="about-container">
          <div className="about-image-container">
            <img width={"10%"} src="zaltr.jpg" />
          </div>
          <h3>About Us</h3>
          <p>{about}</p>
        </div>

        <div className="profiles">
          <div className="profile">
            {/* <div className="avatar">Alloyce</div> */}
            <h4>Alloyce</h4>
            <p>Executive Director 2ution</p>
            <Button
              className="btn"
              variant="outlined"
              onClick={() => (window.location = "mailto:alloyce254@gmail.com")}
            >
              Contact Me
            </Button>
          </div>
          <div className="profile">
            {/* <div className="avatar">Bonnie</div> */}
            <h4>Bonface</h4>
            <p>Senior Developer 2ution</p>
            <Button
              className="btn"
              variant="outlined"
              onClick={() =>
                (window.location = "mailto:mugwira.kiarie@gmail.com")
              }
            >
              Contact Me
            </Button>
          </div>
        </div>
        <div className="copyright-container">
          <span>
            <CopyrightIcon />
            2023 2ution.com
          </span>
        </div>
      </div>
    </Page>
  );
};

export default About;
