import React, { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  query,
  getDocs,
  where,
  collection,
  updateDoc,
} from "firebase/firestore";
import { db, auth, storage } from "../../config/firebase";
import "../../css/ProfilePage.css";
import CircularProgress from "@mui/material/CircularProgress";
import Page from "../../components/Page";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import Following from "../../components/Following";
import Followers from "../../components/Followers";
import { Avatar } from "@mui/material";
import uuid from "react-uuid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import LoadingPage from "../../components/LoadingPage";

const Profile = () => {
  const [user] = useAuthState(auth);
  const [userProfile, setUserProfile] = useState(null);
  const [userRef, setUserRef] = useState(null);
  const [interactions, setInteractions] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [photoUpload, setPhotoUpload] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [doneUpload, setDoneUpload] = useState(false);
  const usersCollection = collection(db, "users");
  const viewsCollection = collection(db, "views");
  const navigate = useNavigate();

  useEffect(() => {
    const q = query(usersCollection, where("uid", "==", user.uid));
    getDocs(q)
      .then((docs) => {
        setUserProfile(docs.docs.at(0).data());
        setUserRef(docs.docs.at(0).ref);

        // (docs.docs.at(0).data());
      })
      .catch((err) => console.log(err));

    const q2 = query(viewsCollection, where("creator", "==", user.uid));
    getDocs(q2)
      .then((docs) => {
        setInteractions(docs.docs.length);
      })
      .catch((err) => console.log(err));
  }, [user, interactions]);

  const updatePhoto = () => {
    const photoRef = ref(
      storage,
      `profile_photos/${uuid() + photoUpload.name}`
    );

    const photoUploadTask = uploadBytesResumable(photoRef, photoUpload);
    photoUploadTask.on(
      "state_changed",
      () => {},
      (err) => console.log(err),
      () => {
        getDownloadURL(photoUploadTask.snapshot.ref).then((url) => {
          setPhotoUrl(url);
          setDoneUpload(true);
        });
      }
    );
  };

  useEffect(() => {
    if (photoUrl) {
      updateDoc(userRef, { photo_url: photoUrl });
    }
  }, [photoUrl]);
  useEffect(() => {
    if (photoUpload) {
      updatePhoto();
    }
  }, [photoUpload]);

  return userProfile ? (
    <Page>
      <div className="profile-page">
        <h4
          className="profile-display-name"
          style={{ fontSize: 30, padding: 0, margin: 5, fontWeight: 600 }}
        >
          {user.displayName}
        </h4>
        {userProfile.country === "" ||
        !userProfile.country ||
        !userProfile.dateOfBirth ? (
          <Button
            variant="outlined"
            className="custom-btn"
            onClick={() => navigate("/complete-profile")}
          >
            Complete Profile
          </Button>
        ) : (
          <div className="profile-container">
            <div className="photo-container">
              <Avatar
                src={userProfile && userProfile.photo_url}
                sx={{
                  width: "7vw",
                  height: "7vw",
                  border: "2px solid dodgerblue",
                  opacity: 0.5,
                  background:
                    userProfile && userProfile.photo_url ? null : "white",
                }}
              >
                <label
                  for="photo-upload"
                  style={{
                    color: "dodgerblue",
                    fontSize: "small",
                    zIndex: 100,
                  }}
                >
                  {userProfile && userProfile.photo_url
                    ? "Change photo"
                    : "Add photo"}
                </label>
                <input
                  name="video-input"
                  id="photo-upload"
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    setPhotoUpload(event.target.files[0]);
                  }}
                />
              </Avatar>
            </div>

            <div className="profile-content-container">
              <div className="profile-content">
                <div className="profile-content-text">
                  <span>country : {userProfile.country}</span>
                  <span>
                    date of birth :{" "}
                    {new Date(
                      userProfile.dateOfBirth.seconds * 1000
                    ).toDateString()}
                  </span>
                </div>
              </div>
              <Button
                variant="outlined"
                className="change-btn"
                onClick={() => navigate("/complete-profile")}
                sx={{ maxWidth: "150px", margin: "10px" }}
              >
                Change
              </Button>
            </div>
            <div className="monetize-container">
              <Button
                variant="outlined"
                style={{ marginTop: 20, maxHeight: 40 }}
                onClick={() => navigate("/upload")}
                endIcon={<PaidOutlinedIcon />}
                disabled
              >
                Monetize
              </Button>
              <Button
                variant="outlined"
                style={{ marginTop: 20, maxHeight: 40 }}
                onClick={() => navigate("/upload")}
              >
                Upload Content
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="followers-following-container">
        <h4
          onClick={() => setActiveTab(0)}
          style={
            activeTab == 0 ? { borderBottom: "2px solid dodgerblue" } : null
          }
        >
          Following
        </h4>
        <h4
          style={
            activeTab == 1 ? { borderBottom: "2px solid dodgerblue" } : null
          }
          onClick={() => setActiveTab(1)}
        >
          Followers
        </h4>
      </div>
      {activeTab == 0 ? (
        <div>
          <Following />
        </div>
      ) : (
        <div>
          <Followers />
        </div>
      )}
    </Page>
  ) : (
    <LoadingPage />
  );
};

export default Profile;
