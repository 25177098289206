import React from "react";
import Page from "../../components/Page";
import "../../css/SchoolHome.css";
import { Button } from "@mui/material";
import { auth, db } from "../../config/firebase";
import { useParams } from "react-router-dom";
import { getDoc, collection, doc, updateDoc } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const NewTutor = () => {
  const { id } = useParams();
  const [schoolData, setSchoolData] = useState(null);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const addTutor = async () => {
    const schoolRef = doc(db, "schools", id);
    const data = await (await getDoc(schoolRef)).data();
    const newData = {
      tutors: [...data.tutors, user.uid],
    };
    updateDoc(schoolRef, newData)
      .then((message) => {
        navigate(`/school/${id}`);
      })
      .catch((err) => console.log(err));
  };

  const getSchoolData = async () => {
    const schoolRef = doc(db, "schools", id);
    const data = await (await getDoc(schoolRef)).data();
    setSchoolData(data);
  };

  useEffect(() => {
    getSchoolData();
  }, []);

  return (
    <Page>
      <div className="school-page">
        <h3>Do you want to join {schoolData.name} as a tutor?</h3>
        <Button variant="outlined" onClick={addTutor}>
          Join
        </Button>
      </div>
    </Page>
  );
};

export default NewTutor;
