import "../../css/LoginPage.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  db,
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from "../../config/firebase";
import { blue } from "@mui/material/colors";
import { useAuthState } from "react-firebase-hooks/auth";
import GoogleIcon from "@mui/icons-material/Google";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert } from "@mui/material";
import { query, getDocs, collection, where } from "firebase/firestore";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const usersCollection = collection(db, "users");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const authenticate = async () => {
    if (password == "") {
      alert("Enter password");
      return;
    } else if (email == "") {
      alert("Enter email");
      return;
    }
    await logInWithEmailAndPassword(email, password).then(() => {
      // if (user === null) {
      //   alert("Invalid email or password");
      // }
    });
  };
  useEffect(() => {}, [user]);
  if (user) {
    const q = query(usersCollection, where("uid", "==", user.uid));
    getDocs(q).then((docs) => {
      const data = docs.docs.at(0).data();
      if (data.country === "" || data.region === "" || data.age === 0) {
        navigate("/complete-profile");
      } else {
        navigate("/");
      }
    });
  }
  if (loading) {
    return (
      <div className="loading">
        <CircularProgress />
      </div>
    );
  }
  if (error) {
    return <Alert>{error}</Alert>;
  }
  return (
    <div className="login-page">
      <div className="login-container">
        <img src="logo.svg" alt="2ution" width={"100%"} height={"35%"} />
        {/* <TextField
          className="username"
          id="outlined-basic"
          label="Email"
          variant="outlined"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          sx={{ padding: 0 }}
        />
        <TextField
          className="password"
          id="outlined-basic"
          label="Password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          sx={{ backgroundColor: blue[500] }}
          className="log-in-btn"
          onClick={authenticate}
        >
          Log In
        </Button>
        <a
          style={{
            color: "dodgerblue",
            fontSize: "small",
            marginTop: "5px",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/password-reset")}
        >
          Forgot Password?
        </a> */}
        <Button
          variant="outlined"
          className="log-in-btn"
          startIcon={<GoogleIcon color="primary" />}
          onClick={async () => await signInWithGoogle()}
        >
          Sign in with Google
        </Button>
      </div>
      <div className="signup-container">
        <span>Don't have an account?</span>
        <Button
          variant="contained"
          className="sign-up-btn"
          onClick={() => navigate("/signup")}
          sx={{ backgroundColor: blue[500] }}
        >
          SIGN UP
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
