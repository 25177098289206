import React from "react";
import "../css/HomePage.css";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { query, getDocs, where, collection } from "firebase/firestore";
import { db, auth } from "../config/firebase";
import Channel from "./Channel";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import { useNavigate } from "react-router-dom";

const Channels = () => {
  const [user] = useAuthState(auth);
  const [userProfile, setUserProfile] = useState();
  const [allSchools, setAllSchools] = useState([]);
  const [mySubscriptions, setMySubscriptions] = useState([]);
  const navigate = useNavigate();
  const schoolsCollection = collection(db, "schools");
  const subscriptionsCollection = collection(db, "subscriptions");
  const colRef = collection(db, "users");

  const getSubscriptions = () => {};

  useEffect(() => {
    if (allSchools.length > 0 || mySubscriptions.length > 0 ) return;

    if (user) {
      const q = query(subscriptionsCollection, where("uid", "==", user.uid));
      var data = [];
      getDocs(q).then((docs) => {
        docs.docs.map((doc) => data.push(doc.data().institution));
      });
      const q2 = query(colRef, where("uid", "==", user.uid));
    getDocs(q) // or getDocs(q) if you created a query
  .then((querySnapshot) => {
    const doc = querySnapshot.docs[0]; // Array of DocumentSnapshot objects
    setUserProfile(doc);
  })
  .catch((error) => {
    console.error("Error getting documents:", error);
  });
    }
    const q2 = query(schoolsCollection);
    getDocs(q2)
      .then((docs) => {
        const allData = [];
        const subscribedData = [];
        docs.docs.filter(doc => doc.data()?.approved).map((doc) => {
          if (
            user &&
            (data.indexOf(doc.id) >= 0 ||
              doc.data().tutors.indexOf(user.uid) >= 0)
          ) {
            subscribedData.push({ ...doc.data(), id: doc.id });
          } else {
            allData.push({ ...doc.data(), id: doc.id });
          }
        });
        setAllSchools(allData);
        setMySubscriptions(subscribedData);
      })
      .catch((err) => console.log(err));
  }, [allSchools, mySubscriptions, user]);

  return (
    <div className="channels-container">
      {userProfile && !userProfile.school && <Button
        variant="outlined"
        sx={{
          padding: 0,
          paddingLeft: 1,
          paddingRight: 1,
          fontSize: "0.7rem",
          flexDirection: "column",
        }}
        startIcon={<AddHomeWorkIcon />}
        onClick={() => navigate("/school/new")}
      >
        Tutor it
      </Button>}
      {mySubscriptions &&
        mySubscriptions.map((data) => (
          <Channel
            key={data.id}
            data={data}
            locked={false}
            noVideos={data.videos.length}
          />
        ))}
      {allSchools &&
        allSchools.map((data) => (
          <Channel
            key={data.id}
            data={data}
            locked={true}
            noVideos={data.videos.length}
          />
        ))}
    </div>
  );
};

export default Channels;
