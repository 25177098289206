import React, { useEffect } from "react";
import "../../css/HomePage.css";
import "../../css/ChannelPage.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  query,
  getDocs,
  where,
  collection,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { auth, db, storage } from "../../config/firebase";
import { ref, deleteObject } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import VideoCard from "../../components/VideoCard";
import Page from "../../components/Page";
import { Button } from "@mui/material";
import MobileVideoCard from "../../components/MobileVideoCard";
import useMediaQuery from "@mui/material/useMediaQuery";


const ChannelPage = () => {
  const { id } = useParams();
  const [allVideos, setAllVideos] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [user] = useAuthState(auth);
  const videosCollection = collection(db, "videos");
  const likesCollection = collection(db, "likes");
  const viewsCollection = collection(db, "views");
  const bigScreen = useMediaQuery("(min-width:600px)");


  // window.location.reload();
  const deleteVideo = (video) => {
    const docRef = doc(db, "videos", video.id);
    const videoRef = ref(storage, video.video_path);
    const thumbNailRef = ref(storage, video.thumbNail_path);

    const likesQ = query(likesCollection, where("video_id", "==", video.id));
    const viewsQ = query(viewsCollection, where("video_id", "==", video.id));

    

    deleteDoc(docRef).then(() => {
      deleteObject(videoRef)
      .then(() => deleteObject(thumbNailRef))
      .then(() => setDeleted(true))
      
      getDocs(likesQ)
      .then((docs) =>
        docs.docs.map((doc) => deleteDoc(doc.ref)))
      getDocs(viewsQ)
      .then((docs) =>
        docs.docs.map((doc) => deleteDoc(doc.ref)))
    }
    )
      
  };

  useEffect(() => {
    const temp = [];
    const q = query(videosCollection, where("user_id", "==", id));
    getDocs(q)
      .then((docs) =>
        docs.docs.map((doc) => temp.push({ ...doc.data(), id: doc.id }))
      )
      .then(() => setAllVideos(temp));
  }, [deleted, id]);
  return (
    <Page>
      <div className="channel-page">
        <h3 style={{ fontWeight: 400, textAlign:"center" }}>All Contents</h3>
        <div className="channel-videos">
          <div className="channel-content">
            <div className="my-channel-videos">
              {allVideos &&
                allVideos.map((video, index) => {

                  return bigScreen ?  (<div key={index} className="video-card-container">
                  <VideoCard data={video} style={{ paddingBottom: "30px"}} />
                 
                 { id === user.uid && (
                   <Button
                     variant="outlined"
                     color="error"
                     onClick={() => deleteVideo(video)}
                     sx={{ marginBottom: "10px"}}
                   >
                     Delete
                   </Button>
                 )}
                
               </div> )  : <MobileVideoCard key={index} data={video} /> 
                })}
              
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ChannelPage;
