import React, { useEffect } from "react";
import "../css/UploadPage.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, addDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { blue } from "@mui/material/colors";
import uuid from "react-uuid";
import { serverTimestamp } from "firebase/firestore";
import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import { Alert, TextField, Button, Snackbar } from "@mui/material";
import { auth, storage, db, inputBucket } from "../config/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Category from "../components/Category";
import Page from "../components/Page";
import topics from "../data/topics";
import educationLevels from "../data/educationLevels";
import { CountryRegionData } from "react-country-region-selector";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Upload = (props) => {
  const schoolUpload = props.schoolUpload;
  const id = props.id;
  const [country, setCountry] = useState("");
  const [videoUpload, setVideoUpload] = useState(null);
  const [thumbNailUpload, setThumbNailUpload] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbNailUrl, setThumbNailUrl] = useState("");
  const [description, setDescription] = useState("");
  const [reference, setRef] = useState("");
  const [videoPercent, setVideoPercent] = useState(0);
  const [thumbNailPercent, setThumbNailPercent] = useState(0);
  const [successUpload, setSuccessUpload] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [thumbNail_path, setThumbNail_path] = useState("");
  const [video_path, setVideo_path] = useState("");
  const [streamUrl, setStreamUrl] = useState("");
  const [isPremium, setIsPremium] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  var countries = [];
  CountryRegionData.map((countryData) => {
    countries.push(countryData.at(0));
  });
  const thumbNail_path_const = schoolUpload
    ? "schools/thumbnails"
    : "thumbnails";

  const uploadVideo = () => {
    if (
      videoUpload &&
      thumbNailUpload &&
      title !== "" &&
      category !== "" &&
      educationLevel !== ""
    ) {
      const video_uuid = uuid();
      setVideo_path(`${video_uuid}`);
      const videoRef = ref(
        inputBucket,
        `${video_uuid}`
      );
      setThumbNail_path(
        `${thumbNail_path_const}/${video_uuid}`
      );
      const thumbNailRef = ref(
        storage,
        `${video_uuid}`
      );
      const videoUploadTask = uploadBytesResumable(videoRef, videoUpload);
      videoUploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setVideoPercent(percent);
        },
        (err) => console.log(err),
        () => {
          console.log("done");
          getDownloadURL(videoUploadTask.snapshot.ref).then((url) => {
            setVideoUrl(url);
            console.log("set url");
            

            setStreamUrl(`content.2ution.com/${video_uuid}/manifest.m3u8`)
            console.log("set dash url");
          });
        }
      );
      const thumbNailUploadTask = uploadBytesResumable(
        thumbNailRef,
        thumbNailUpload
      );
      thumbNailUploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setThumbNailPercent(percent);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(thumbNailUploadTask.snapshot.ref).then((url) => {
            setThumbNailUrl(url);
          });
        }
      );
    } else {
      console.log("Not Run");
    }
  };

  const handleClose = () => {
    if (schoolUpload) {
      navigate(`/school/${id}`);
    } else {
      navigate("/");
    }
  };

  // const convert = (isSchool, id) => {
  //   const video_url = isSchool
  //     ? `https://api.2ution.com/convert_school_video?Id=${id}`
  //     : `https://api.2ution.com/convert_video?Id=${id}`;
  //   fetch(video_url, {
  //     method: "get",
  //     credentials: "same-origin",
  //   });
  // };

  useEffect(() => {
    if (videoUrl !== "" && thumbNailUrl !== "") {
      console.log("creating doc..");
      var data = {
        date_added: serverTimestamp(),
        description: description,
        thumbnail_url: thumbNailUrl,
        thumbNail_path: thumbNail_path,
        title: title,
        user_id: user.uid,
        video_url: videoUrl,
        video_path: video_path,
        references: reference,
        views: 0,
        likes: 0,
        isPremium: isPremium,
        category: category,
        educationLevel: educationLevel,
        country: country,
        streamUrl: streamUrl
      };

      if (schoolUpload) {
        const schoolVideosColl = collection(db, "school_videos");
        const schoolRef = doc(db, "schools", id);

        getDoc(schoolRef).then((doc) => {
          data = { ...data, country: doc.get("country"), school: id };
        });

        addDoc(schoolVideosColl, data)
          .then((docRef) => {
            getDoc(schoolRef).then((doc) =>
              updateDoc(schoolRef, {
                videos: [...doc.get("videos"), docRef.id],
              })
            );
            // convert(true, docRef.id);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const videosColl = collection(db, "videos");
        addDoc(videosColl, data)
          .then((docRef) => {
            // convert(true, docRef.id);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      setVideoUpload(null);
      setThumbNailUpload(null);
      setSuccessUpload(true);
      setDescription("");
      setThumbNailUrl("");
      setVideoUrl("");
    }
  }, [videoPercent, thumbNailPercent, videoUrl, thumbNailUrl]);
  return (
    <Page>
      <div className="upload-content">
        <Snackbar
          open={successUpload}
          autoHideDuration={1500}
          onClose={handleClose}
        >
          <Alert
            severity="success"
            onClose={() => {
              setSuccessUpload(false);
            }}
          >
            <strong>You have successfully uploaded a video</strong>
          </Alert>
        </Snackbar>
        <div className="row-upload">
          <TextField
            id="standard-basic"
            label="Title"
            className="title-input"
            variant="standard"
            value={title}
            inputProps={{ maxLength: 60 }}
            onChange={(event) => setTitle(event.target.value)}
          />
          <div className="category-container">
            <Category
              category={category}
              setCategory={setCategory}
              topics={topics}
              categoryTitle="Subject"
            />
          </div>
        </div>
        <div className="country-education">
          <Category
            category={educationLevel}
            setCategory={setEducationLevel}
            topics={educationLevels}
            style={{ width: "40%", borderColor: "grey" }}
            categoryTitle="Education level"
          />
          <div className="premium-content-container">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={isPremium} onChange={()=>setIsPremium(!isPremium)} />} label="Premium Content" labelPlacement="start" />
          </FormGroup>
          </div>
          {/* {!schoolUpload && (
            <Category
              category={country}
              setCategory={setCountry}
              topics={countries}
              style={{ width: "40%", borderColor: "grey" }}
              categoryTitle="Country Select"
            />
          )} */}
        </div>
        <div className="upload-content-container">
          <div className="video-upload-container upload-container">
            {videoUpload ? <h4>{videoUpload.name}</h4> : <h4>Upload Video</h4>}
            {videoUpload ? (
              <CircularProgressWithLabel value={videoPercent} />
            ) : null}
            <label for="video-upload" class="custom-file-upload">
              Choose Video
            </label>
            <input
              name="video-input"
              id="video-upload"
              type="file"
              accept="video/*"
              onChange={(event) => setVideoUpload(event.target.files[0])}
            />
          </div>

          <div className="thumbnail-upload-container upload-container">
            {thumbNailUpload ? (
              <h4>{thumbNailUpload.name}</h4>
            ) : (
              <h4>Upload Thumbnail</h4>
            )}
            {thumbNailUpload ? (
              <CircularProgressWithLabel value={thumbNailPercent} />
            ) : null}
            <label for="thumbnail-upload" class="custom-file-upload">
              Choose Thumbnail
            </label>
            <input
              name="thumbnail-input"
              id="thumbnail-upload"
              type="file"
              accept="image/*"
              onChange={(event) => setThumbNailUpload(event.target.files[0])}
            />
          </div>
        </div>
        <TextField
          id="filled-multiline-static"
          label="Description"
          multiline
          rows={9}
          variant="filled"
          className="description-input"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <TextField
          sx={{ mt: 2 }}
          id="filled-multiline-static"
          label="References"
          multiline
          variant="filled"
          rows={3}
          value={reference}
          className="ref-input"
          onChange={(event) => setRef(event.target.value)}
        />
        <Button
          sx={{ backgroundColor: blue[400], mt: 3 }}
          variant="contained"
          onClick={uploadVideo}
        >
          Upload
        </Button>
      </div>
    </Page>
  );
};

export default Upload;
