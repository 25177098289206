import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import "../css/HomePage.css";
import { CountryRegionData } from "react-country-region-selector";

const Channel = (props) => {
  const locked = props.locked;
  const schoolName = props.data.name;
  const noVideos = props.noVideos;
  const logo_url = props.data.logo_url;
  const country = props.data.country;
  const id = props.data.id;
  const navigate = useNavigate();

  const getCountryShort = (countryData) => {
    const cName = CountryRegionData.filter(
      (elem) => elem[0] === countryData
    )[0][1];

    return cName;
  };

  const handleClick = () => {
    navigate(`/school/${id}`);
    // if (locked) {
    //   navigate(`/school/subscribe/${id}`);
    // } else {
      
    // }
  };

  useEffect(() => {}, []);

  return (
    <div className="channel-avatar" onClick={handleClick}>
      <div className="avatar-container">
        <Avatar
          sx={{
            maxWidth: 40,
            maxHeight: 40,
            margin: 0,
          }}
          aria-label="Name"
          src={logo_url}
        ></Avatar>
        {locked && (
          <div className="locked-icon-container">
            <LockOutlinedIcon
              className="lock"
              sx={{ fontSize: "0.9rem", color: "white" }}
            />
          </div>
        )}
      </div>
      <span className="school-name">{schoolName}</span>
      <div className="videos-country">
        <div className="country-icon-container">
          <img
            alt={country}
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryShort(
              country
            )}.svg`}
          />
        </div>
        <span className="school-videos-count">
          {noVideos}
          {/* {noVideos == 1 ? "video" : "videos"} */}
        </span>
      </div>
    </div>
  );
};

export default Channel;
