import "../css/VideoPlayPage.css";
import React from "react";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { db } from "../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Report = (data) => {
  const [open, setOpen] = useState(false);
  const [reportText, setReportText] = useState("");

  const reportCollection = collection(db, "reports");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    addDoc(reportCollection, {
      complainant: data.uid,
      video_id: data.videoId,
      report: reportText,
    })
      .then(() => {
        setOpen(false);
        setReportText("");
      })
      .catch(() => {
        console.log("Cannot report");
      });
  };
  useEffect(() => {}, [open]);
  return (
    <div>
      <Button variant="outlined" color="error" onClick={handleClickOpen}>
        Report
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your complaint against the content
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="report"
            type="email"
            fullWidth
            variant="standard"
            onChange={(event) => setReportText(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Report;
