import "../css/VideoPlayPage.css";
import React, { useEffect } from "react";
import { db } from "../config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { red } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";

const Comment = (commentData) => {
  const [postedBy, setPostedBy] = useState("");
  const usersCollection = collection(db, "users");
  const q = query(usersCollection, where("uid", "==", commentData.comment.uid));
  useEffect(() => {
    getDocs(q).then((comment_docs) => {
      setPostedBy(comment_docs.docs.at(0).data().photo_url);
    });
  }, []);
  return (
    <div className="comment">
      <Avatar
        sx={{ bgcolor: red[500], maxWidth: 25, maxHeight: 25, margin: 0 }}
        aria-label="recipe"
        src={postedBy}
      ></Avatar>
      <span className="comment-text"> {commentData.comment.comment}</span>
    </div>
  );
};

export default Comment;
