import React, { useState, useEffect } from "react";
import { query, getDocs, where, collection } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../config/firebase";
import AccountData from "./AccountCard";

const Following = () => {
  const [following, setFollowing] = useState([]);
  const [user] = useAuthState(auth);
  const usersCollection = collection(db, "users");

  useEffect(() => {
    const q = query(usersCollection, where("uid", "==", user.uid));
    getDocs(q).then((docs) => {
      setFollowing(docs.docs.at(0).data().following);
    });
  }, [following]);
  return (
    <div className="followers">
      {following &&
        following.map((id, index) => {
          if (id !== "") {
            return <AccountData key={index} id={id} />;
          }
        })}
    </div>
  );
};

export default Following;
