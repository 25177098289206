import "./App.css";
import AdminRouter from "./pages/Admin/AdminRouter";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NewSchool from "./pages/School/NewSchool";
import SchoolHome from "./pages/School/SchoolHome";
import SubscribePage from "./pages/School/SubscribePage";
import NoPage from "./pages/NoPage";
import PrivateRoute from "./components/PrivateRoute";
import HomePage from "./pages/public/HomePage";
import LoginPage from "./pages/public/LoginPage";
import SigninPage from "./pages/public/SignInPage";
import VideoPlayPage from "./pages/public/VideoPlayPage";
import ChannelPage from "./pages/public/ChannelPage";
import CompleteProfile from "./pages/public/CompleteProfile";
import Profile from "./pages/public/Profile";
import Liked from "./pages/public/Liked";
import Watched from "./pages/public/Watched";
import About from "./pages/public/About";
import UploadPage from "./pages/public/UploadPage";
import NewTutor from "./pages/School/NewTutor";
import SchoolUpload from "./pages/School/SchoolUpload";
import SchoolPlayPage from "./pages/School/SchoolPlayPage";
import SubscriptionConfirm from "./pages/School/SubscriptionConfirm";
import PasswordReset from "./pages/public/PasswordReset";

function App() {
  var domain = window.location.hostname;
  var subdomain = domain.split(".")[0];
  if (subdomain == "admin") {
    return <AdminRouter />;
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route index path="login" element={<LoginPage />} />
          <Route index path="signup" element={<SigninPage />} />
          <Route index path="password-reset" element={<PasswordReset />} />
          <Route
            path="play/:id"
            element={
              <PrivateRoute>
                <VideoPlayPage />
              </PrivateRoute>
            }
          />
          <Route
            path="channel/:id"
            element={
              <PrivateRoute>
                <ChannelPage />
              </PrivateRoute>
            }
          />
          <Route
            path="complete-profile"
            element={
              <PrivateRoute>
                <CompleteProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="upload"
            element={
              <PrivateRoute>
                <UploadPage />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="favorites"
            element={
              <PrivateRoute>
                <Liked />
              </PrivateRoute>
            }
          />
          <Route
            path="viewed"
            element={
              <PrivateRoute>
                <Watched />
              </PrivateRoute>
            }
          />
          <Route path="about" element={<About />} />
          <Route
            path="/school/new"
            element={
              <PrivateRoute>
                <NewSchool />
              </PrivateRoute>
            }
          />
          <Route
            path="/school/subscribe/:id"
            element={
              <PrivateRoute>
                <SubscribePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/school/join/:id"
            element={
              <PrivateRoute>
                <NewTutor />
              </PrivateRoute>
            }
          />
          <Route
            path="/school/upload/:id"
            element={
              <PrivateRoute>
                <SchoolUpload />
              </PrivateRoute>
            }
          />
          <Route
            path="/school/play/:id"
            element={
              <PrivateRoute>
                <SchoolPlayPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/school/:id"
            element={
              <PrivateRoute>
                <SchoolHome />
              </PrivateRoute>
            }
          />
          <Route
            path="/confirm_subscription"
            element={
              <PrivateRoute>
                <SubscriptionConfirm />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
