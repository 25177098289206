import "../css/VideoPlayPage.css";
import React from "react";

const DiscussionReferences = (props) => {
  const references = props.references;
  const discussion = props.discussion;
  const discussionVisible = props.visibility;
  return (
    <>
      {discussionVisible && (
        <div>
          {discussion ? (
            <div className="description-container">
              <h4>Read More</h4>
              <p className="description-display">{discussion}</p>
            </div>
          ) : null}
          {references ? (
            <div className="ref-display">
              <h4>Reference</h4>
              {references.split("\n").map((ref) => (
                <a className="ref-link" href={ref}>
                  {ref}
                </a>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default DiscussionReferences;
