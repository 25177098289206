import CircularProgress from "@mui/material/CircularProgress";
import Page from "./Page";

const LoadingPage = () => {
  return (
    <Page>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </div>
    </Page>
  );
};

export default LoadingPage;
