const about = `Welcome to 2ution, the global platform for personalized academic content. We empower learners at all levels to access educational materials tailored to their needs. Our inclusive ecosystem enables users to explore, create, and share knowledge with a community of like-minded individuals.

At 2ution, we believe in the transformative power of education. Our platform offers a wide range of user-generated content, covering elementary concepts to advanced topics. We strive to provide a supportive space where learners, educators, and lifelong enthusiasts can connect and engage in a dynamic exchange of ideas.

We are committed to delivering a seamless user experience. Through innovative technologies, we make it easy for users to navigate our platform, discover relevant content, and connect with others. Personalized recommendations and an intuitive interface enhance the learning journey, ensuring education is accessible and enjoyable.

As a responsible platform, we prioritize accuracy and integrity. We encourage users to contribute reliable and curated materials, fostering a culture of knowledge sharing and collaboration. Our commitment to maintaining high-quality content sets us apart, providing users with valuable resources for their educational pursuits.

2ution is more than a platform; it is a global community of passionate learners and educators. Together, we unlock the full potential of education, empowering individuals to succeed. Join us on this exciting journey of discovery and growth.

Thank you for being a part of the 2ution experience.`;

export default about;
