import "../../css/HomePage.css";
import VideoCard from "../../components/VideoCard";
import { db } from "../../config/firebase";
import React, { useState, useEffect } from "react";
import { getDocs, collection, query, where, limit } from "firebase/firestore";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Chip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import Page from "../../components/Page";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import topics from "../../data/topics";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Channels from "../../components/Channels";
import Subject from "../../components/Subject";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";

const HomePage = () => {
  const [data, setData] = useState([]);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const bigScreen = useMediaQuery("(min-width:600px)");

  // const videosCollection = collection(db, "school_videos");

  // useEffect(() => {
  //   var allVideos = [];
  //   topics.map((topic) => {
  //     const q = query(
  //       videosCollection,
  //       where("category", "==", topic),
  //       limit(4)
  //     );
  //     var videos = [];
  //     getDocs(q)
  //       .then((dataDocs) =>
  //         dataDocs.docs.map((data_doc) => {
  //           videos.push({
  //             ...data_doc.data(),
  //             id: data_doc.id,
  //           });
  //         })
  //       )
  //       .then(() => {
  //         allVideos.push({ category: topic, data: videos });
  //         setData(allVideos);
  //       });
  //   });
  // }, []);

  const iconStyle = {
    fontSize: 40,
    strokeWidth: 1,
    stroke: "#EDEBF1",
  };

  return (
    <Page>
      <div className="home-page">
        {bigScreen && (
          <div className="side-nav">
            <div className="side-nav-item">
              <IconButton onClick={() => navigate("/")}>
                <HomeOutlinedIcon sx={iconStyle} />
              </IconButton>
            </div>

            {/* <div className="side-nav-item">
              <IconButton onClick={() => navigate("/upload")}>
                <FileUploadOutlinedIcon sx={iconStyle} />
              </IconButton>
            </div> */}
            <div className="side-nav-item">
              <IconButton disabled>
                <NotificationsOutlinedIcon sx={iconStyle} />
              </IconButton>
            </div>
            <div className="side-nav-item">
              <IconButton onClick={() => navigate("/about")}>
                <InfoOutlinedIcon sx={iconStyle} />
              </IconButton>
            </div>
          </div>
        )}
        <div className="home-page-content">
          <div className="fixed">
            <Channels />
            <div className="chips">
              {topics.map((topic, index) => (
                <div key={index} style={{ display: "inline-block" }}>
                  <Chip
                    className="topic-chip"
                    component="a"
                    label={topic}
                    href={`#${topic}`}
                    variant="outlined"
                    clickable
                    sx={{ marginLeft: 1 }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="content">
            {topics.map((topic) => (
              <Subject key={topic} topic={topic} user={user} />
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default HomePage;
