import "../../css/HomePage.css";
import React, { useState, useEffect } from "react";
import Page from "../../components/Page";
import {
  collection,
  getDocs,
  query,
  where,
  getDoc,
  doc,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../config/firebase";
import VideoCard from "../../components/VideoCard";

const Liked = () => {
  const [user] = useAuthState(auth);
  const [likedRef, setLikedRef] = useState([]);
  const [liked, setLiked] = useState([]);
  const [set, setSet] = useState(false);
  const likesCollection = collection(db, "likes");

  const getRef = () => {
    const q = query(likesCollection, where("uid", "==", user.uid));
    var ref = [];
    getDocs(q)
      .then((docs) => {
        docs.docs.map((doc) => ref.push(doc.data()));
      })
      .then(() => setLikedRef(ref))
      .then(() => {
        var videos = [];
        likedRef.map((ref) => {
          var likedDoc = doc(db, "videos", ref.video_id);
          getDoc(likedDoc).then((video) =>
            videos.push({ ...video.data(), id: video.id })
          );
        });
        setLiked(videos);
      });
  };
  const getVideos = () => {
    if (likedRef.length > 0) {
      setSet(true);
    }
    return;
  };
  useEffect(() => {
    // if (!set) {
    getRef();
    // getVideos();
    // }
  }, []);
  return (
    <Page>
      <h3>My Favorites</h3>
      <div className="videos">
        {liked.map((video, index) => (
          <VideoCard key={index} data={video} />
        ))}
      </div>
    </Page>
  );
};

export default Liked;
